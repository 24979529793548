export const TAGS = {
  a11y: {
    name: "Accessibility"
  },
  alert: {
    name: "Alert",
  },
  misc: {
    name: "Miscellaneous"
  },
};
